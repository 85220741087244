<template>
  <div class="content">
    <div>
      <OpenAppHead :options="options" @getshowimg="getshowimg" />
    </div>
    <div class="banner">
      <img :src="mobileNewList.topImg">
    </div>
    <div class="main">
      <div class="main-content">
        <div
          v-for="(item, index) in mobileNewList.list"
          :key="index"
          class="main-content-container"
          :class="index === 0 ? 'main-content-container-left' : 'main-content-container-right'"
          @click="openMobile(item)"
        >
          <div class="main-content-container-tag">
            <p
              v-for="(value, l) in item.tag"
              :key="l"
              class="main-content-container-tag-unit"
            >{{ value }}</p>
          </div>
          <div class="main-content-container-h1">
            <span>话费充值</span>
            <span style="font-size: 28px; font-weight: 400;">{{ item.discount }}</span>
            <span>折</span>
          </div>
          <div class="main-content-container-h2">超值优惠</div>
          <div class="main-content-container-btn">立即充值<img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-12/17/yuelvhuigEG4Sl3bUG1631437601.png" alt=""></div>
        </div>
      </div>
      <div class="hint">
        <div class="hint-title">温馨提示</div>
        <p v-for="(item, index) in mobileNewList.explain" :key="index" v-html="item" />
      </div>
      <div class="video">
        <div class="video-title">
          <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-12/17/yuelvhuiPMAG9GyLQM1631437505.png" alt="">
          <span>更多充值优惠</span>
          <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-12/17/yuelvhuiT4Bm0G3zut1631437515.png" alt="">
        </div>
        <div class="video-content">
          <div
            v-for="(item, index) in videoList"
            :key="index"
            class="video-case"
            @click="openVideo(item)"
          >
            <div class="video-case-text">
              <div class="video-case-text-img">
                <img :src="item.image" alt="">
              </div>
              <div class="video-case-text-right">
                <p class="video-case-text-right-h1">{{ item.name }}</p>
                <p class="video-case-text-right-h2">充值<span>5</span>折起</p>
              </div>
            </div>
            <div class="video-case-btn">
              立即充值
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="videoTopUpWrap">-->
    <!--  <div class="videoTopUpTitle">视频充值</div>-->
    <!--  <div class="videoTopUpContent">-->
    <!--    <div-->
    <!--      v-for="(item,index) in videoList"-->
    <!--      :key="index"-->
    <!--      class="videoTopUpContentDetail"-->
    <!--      :style="{backgroundImage:'url(' + item.bgImg+ ')' }"-->
    <!--      @click="openVideo(item)"-->
    <!--    >-->
    <!--      <p class="videoTopUpName">{{ item.name }}</p>-->
    <!--      <p class="videoTopUpBtn" :style="{'color':item.textColor}">立即充值</p>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->
    <!--<div class="mobileTopUpWrap">-->
    <!--  <div class="videoTopUpTitle">手机充值</div>-->
    <!--  <div class="mobileTopUpContent">-->
    <!--    <div-->
    <!--      v-for="(item,index) in mobileList"-->
    <!--      :key="index"-->
    <!--      class="mobileTopUpContentDetail"-->
    <!--      :class="(index/1 === 0 || index/2 === 1) ? 'mobileTopUpContentDetail1' : ''"-->
    <!--      :style="{backgroundImage:'url(' + item.bgcImg+ ')' }"-->
    <!--      @click="openMobile(item)"-->
    <!--    >-->
    <!--      <div class="mobileTopUp">-->
    <!--        <div class="mobileTopUpName">-->
    <!--          <span style="font-size: 33px;">{{ item.faceValue }}</span>-->
    <!--          <span style="font-size: 17px;">元</span>-->
    <!--        </div>-->
    <!--        <p style="font-size: 12px;">售价：{{ item.price }}元</p>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <p class="mobileTopUpBtn">立即充值</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->
    <!--<div v-if="showimg" class="mask" @click="closeshow">-->
    <!--  <div class="picture-box">-->
    <!--    <img class="picture" src="../../../assets/images/wxtwo.jpg">-->
    <!--    <div class="btn">长按图片保存到手机相册</div>-->
    <!--    &lt;!&ndash; <div class="btn" style="background: #ff7800">预览海报长按分享</div> &ndash;&gt;-->
    <!--  </div>-->
    <!--</div>-->
    <!--拦截弹窗-->
    <!--<div class="masks" v-if="showCopyLink">-->
    <!--  <div class="showCopyLinkContent">-->
    <!--    <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/22/yuelvhuijE6PwGSqSU1608647270.png" alt="" class="showCopyLinkImg" @click="copyLink">-->
    <!--    &lt;!&ndash;<br>&ndash;&gt;-->
    <!--    &lt;!&ndash;<img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/21/yuelvhuiSeEpBaYYQm1608644479.png" alt="" class="showCopyLinkClose" @click="closeMask">&ndash;&gt;-->
    <!--  </div>-->
    <!--</div>-->
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import { getHomeList } from '@/services/comeOn.js'
// import { sharePage } from '@/services/activity'
import OpenAppHead from '../../../component/GoodsDetail/openApp'
import { isWeChat } from '@/utils/common/validate'
import { wxConfigInit, showShare } from '@/utils/wx-api'

export default {
  name: 'TopUpIndex',
  components: {
    OpenAppHead
  },
  data() {
    return {
      mobileNewList: {},
      videoList: [],
      mobileList: [],
      phone: '',
      showimg: false,
      // showCopyLink:true,
      topclose: true,
      isWxStyle: false
    }
  },
  computed: {
    options() {
      const obj = {
        showtig: true
      }
      const info = {
        product_type: 46
      }
      obj.info = info
      return obj
    }
  },
  created() {
    // if(this.$route.path === '/test-TopUpIndex'){
    //     this.showCopyLink = false;
    // }
    this.getLifeTopUpData()
    this.SetUpShare()// 分享
  },
  mounted() {
    this.isWxStyle = isWeChat()
    showShare()// 启用分享
  },
  methods: {
    getshowimg(item) {
      this.showimg = item
    },
    isclose(t) {
      this.topclose = t
    },
    // 复制跳转链接
    copyLink() {
      this.$router.push({
        path: '/JumpLink'
      })
    },
    closeshow() {
      this.showimg = false
    },
    SetUpShare() {
      const shareInfo = {
        title: '话费、爱奇艺、优酷、腾讯等充值8折起，更多充值尽在悦淘APP', // 分享标题
        desc: '快和我一起免费领取吧>', // 分享描述
        link: 'https://web.yuetao.vip/TopUpIndex?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-18/19/yuelvhuiXEWvJKJYB21605699651.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    },
    getLifeTopUpData() {
      getHomeList().then(res => {
        if (Number(res.code) === 200) {
          for (let i = 0; i < (res.data.videoList).length; i++) {
            if ((res.data.videoList)[i].name === '爱奇艺会员') {
              (res.data.videoList)[i].textColor = '#1E8B50'
            } else if ((res.data.videoList)[i].name === '优酷会员') {
              (res.data.videoList)[i].textColor = '#AC59EF'
            } else if ((res.data.videoList)[i].name === '腾讯会员') {
              (res.data.videoList)[i].textColor = '#3D90CD'
            }
          }
          this.mobileNewList = res.data.mobileNewList// 新充值
          this.videoList = res.data.videoList// 视频充值
          this.phone = res.data.mobileList.phone// 可充值手机号
          for (let i = 0; i < (res.data.mobileList.list).length; i++) {
            (res.data.mobileList.list)[i].bgcImg = res.data.mobileList.bgImg// 手机充值背景图
          }
          this.mobileList = res.data.mobileList.list// 手机充值
        } else {
          Toast(res.msg)
        }
      })
    },
    openVideo(item) {
      this.$router.push({
        path: '/prerogativePay',
        query: {
          id: item.goodsId,
          price: '',
          indexType: 1,
          payType: 1,
          FromType: 0
        }
      })
    },
    openMobile(item) {
      if (item.type === 1) {
        window.location.href = item.url
      }
      if (item.type === 2) {
        this.$router.push({
          path: '/prerogativePay',
          query: {
            payType: 1,
            indexType: 2,
            homeGoIndex: 1,
            FromType: 1
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #F8F8F8;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  font-family: "PingFang SC Regular";
}

.banner {
  height: 160px;
}
.main {
  border-radius: 10px 10px 0 0;
  background-color: #f8f8f8;
  padding: 24px 12px;
  margin-top: -12px;
  position: relative;
  z-index: 2;
}

.main-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.main-content-container {
  width: calc(50% - 4px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 10px;
  position: relative;
}

.main-content-container-tag {
  padding-left: 5px;
  position: absolute;
  top: -9px;
  display: flex;
  justify-content: left;
}

.main-content-container-tag-unit {
  height: 18px;
  background: #f7263c;
  border-radius: 7px 0px 7px 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  padding: 0 5px;
  margin-left: 10px;
}

.main-content-container-left {
  background-image: url('https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-12/17/yuelvhuiLmBosf27jD1631437362.png');
}

.main-content-container-right {
  background-image: url('https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-12/17/yuelvhuiyJLf235qp71631437475.png');
}

.main-content-container-h1 {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #fe3837;
  white-space: nowrap;
  text-overflow:ellipsis;
}

.main-content-container-h2 {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
  margin-top: 6px;
}

.main-content-container-btn {
  height: 30px;
  background: #333;
  border-radius: 15px;
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  color: #fbe0c5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  img {
    width: 5px;
    height: 9px;
    margin-left: 4px;
  }
}

.hint {
  width: 100%;
  padding: 10px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 40px;
}

.hint p {
  font-size: 14px;
  text-indent: -9px;
  line-height: 20px;
  color: #666;
  text-align: left;
  margin: 5px 0 5px 9px;
  word-wrap: break-word;
  word-break: break-all;
}

.hint-title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 22px;
  margin-bottom: 10px;
}

.video {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #333333;
  margin-bottom: 15px;
}

.video-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  img {
    width: 35px;
    height: 22px;
    margin: 0 15px;
  }
}

.video-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.video-case {
  width: calc(50% - 6px);
  padding: 0 10px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 15px;
}

.video-case-text {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 30px;
  margin-bottom: 15px;
  .video-case-text-img {
    width: 36px;
    height: 36px;
    margin-left: 6px;
    margin-right: 18px;
    img {
      min-width: 36px;
      min-height: 36px;
    }
  }

  .video-case-text-right {
    white-space: nowrap;
    text-overflow:ellipsis;
    .video-case-text-right-h1 {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: #333;
      margin-bottom: 1px;
    }

    .video-case-text-right-h2 {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #333;
      span {
        color: #F7263C;
      }
    }
  }
}

.video-case-btn {
  height: 25px;
  line-height: 25px;
  background-color: #f7263c;
  border-radius: 13px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}
//.videoTopUpWrap, .mobileTopUpWrap {
//  text-align: left;
//  margin-bottom: 20px;
//}
//
//.videoTopUpTitle {
//  font-size: 17px;
//  color: #333333;
//  font-weight: 700;
//  padding-bottom: 17px;
//}
//
//.videoTopUpContentDetail {
//  background-size: 100% 100%;
//  background-repeat: no-repeat;
//  height: 80px;
//  margin-bottom: 10px;
//  padding: 16px 46px;
//}
//
//.videoTopUpName {
//  font-size: 17px;
//  color: #ffffff;
//  font-weight: 700;
//  margin-bottom: 9px;
//}
//
//.videoTopUpBtn {
//  width: 83px;
//  height: 26px;
//  text-align: center;
//  line-height: 26px;
//  font-size: 13px;
//  font-weight: 600;
//  /*color: #1E8B50;*/
//  border-radius: 13px;
//  background-color: #fff;
//}
//
//.mobileTopUpWrap {
//  margin-bottom: 8px;
//}
//
//.mobileTopUpContent {
//  display: flex;
//  flex-wrap: wrap;
//}
//
//.mobileTopUpContentDetail {
//  width: 168px;
//  height: 103px;
//  background-size: 100% 100%;
//  background-repeat: no-repeat;
//  margin-bottom: 10px;
//}
//
//.mobileTopUpContentDetail1 {
//  margin-right: 12px;
//}
//
//.mobileTopUp {
//  padding: 14px 22px;
//  color: #fff;
//}
//
//.mobileTopUpBtn {
//  text-align: center;
//  font-size: 12px;
//  color: #E33F44;
//  font-weight: 700;
//}
//
//.mask {
//  position: fixed;
//  left: 0;
//  top: 0;
//  width: 100%;
//  height: 100%;
//  background: rgba(0, 0, 0, 0.75);
//  z-index: 99;
//
//  .picture-box {
//    position: absolute;
//    left: 50%;
//    top: 50%;
//    transform: translate(-50%, -50%);
//
//    .picture {
//      display: block;
//      width: 280px;
//      // height: 360px;
//      background: rebeccapurple;
//      margin: 0 auto;
//    }
//
//    .btn {
//      width: 290px;
//      height: 44px;
//      border-radius: 22px;
//      background: #ed2e2e;
//      text-align: center;
//      line-height: 44px;
//      font-size: 16px;
//      color: #fff;
//      margin-top: 15px;
//    }
//  }
//
//  .sku {
//    width: 100%;
//    min-height: 500px;
//    border-radius: 8px 8px 0 0;
//    background: #fff;
//    position: absolute;
//    left: 0;
//    bottom: 0;
//    box-sizing: border-box;
//    padding: 14px;
//
//    .tp {
//      display: flex;
//      width: 100%;
//      position: relative;
//      margin-bottom: 20px;
//
//      .goods-cover {
//        width: 78px;
//        height: 78px;
//        border-radius: 4px;
//        background: #e0e0e0;
//      }
//
//      .goods-price {
//        width: 250px;
//        height: 78px;
//        display: flex;
//        // align-items: center;
//        flex-direction: column;
//        justify-content: space-around;
//        box-sizing: border-box;
//        padding-left: 12px;
//
//        .pris {
//          text-align: left;
//
//          .hjy {
//            color: #ed2e2e;
//            font-size: 13px;
//          }
//
//          .hjy-count {
//            color: #ed2e2e;
//            font-size: 18px;
//            font-weight: bold;
//            margin-right: 12px;
//          }
//
//          .spe-icon {
//            font-size: 14px;
//            color: #ed2e2e;
//            font-weight: bold;
//            margin-left: 4px;
//          }
//
//          .gwj {
//            color: #141f33;
//            font-size: 13px;
//          }
//        }
//
//        .guige {
//          text-align: left;
//          font-size: 13px;
//          color: #99a0ad;
//        }
//
//        .close {
//          width: 16px;
//          height: 16px;
//          background: url("../../../assets/images/closesku.png") no-repeat;
//          background-size: 100% 100%;
//          position: absolute;
//          right: 4px;
//          top: 4px;
//        }
//      }
//    }
//
//    .out {
//      height: 200px;
//      overflow: hidden;
//      margin-bottom: 50px;
//
//      .inner {
//        height: 200px;
//        overflow: auto;
//      }
//    }
//
//    .status-box {
//      width: 100%;
//      margin-bottom: 8px;
//
//      .tits {
//        font-size: 16px;
//        color: #141f33;
//        margin-bottom: 12px;
//        text-align: left;
//      }
//
//      .status {
//        width: 100%;
//        overflow: hidden;
//
//        .box {
//          width: auto;
//          padding: 6px 20px;
//          background: #f0f2f5;
//          border-radius: 4px;
//          font-size: 14px;
//          color: #141414;
//          display: inline-block;
//          margin-right: 12px;
//          margin-bottom: 12px;
//          float: left;
//        }
//
//        .act {
//          background: #fae9e8;
//          color: #ed2e2e;
//        }
//      }
//    }
//  }
//
//  .num-box {
//    width: 100%;
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    font-size: 16px;
//    color: #141f33;
//    margin-bottom: 23px;
//
//    .handles {
//      .edge,
//      .add {
//        display: inline-block;
//        width: 28px;
//        height: 28px;
//        background: #f0f2f5;
//        border-radius: 4px;
//        color: #616b80;
//        font-size: 12px;
//        line-height: 28px;
//      }
//
//      .cou {
//        display: inline-block;
//        width: 45px;
//        height: 28px;
//        background: #f0f2f5;
//        border-radius: 4px;
//        color: #616b80;
//        font-size: 12px;
//        line-height: 28px;
//        margin: 0 4px;
//      }
//    }
//  }
//
//  .code-txt {
//    width: 100%;
//    text-align: center;
//    font-size: 12px;
//    color: #99a0ad;
//    margin-bottom: 4px;
//  }
//
//  .btn-box {
//    width: 100%;
//    height: 44px;
//    border-radius: 22px;
//    overflow: hidden;
//    display: flex;
//    justify-content: space-between;
//
//    .btn1 {
//      width: 50%;
//      height: 100%;
//      line-height: 44px;
//      background: #201e1d;
//      color: #fff;
//      font-size: 16px;
//    }
//
//    .btn2 {
//      background: #ff001c;
//    }
//
//    .btn3 {
//      width: 100%;
//      height: 100%;
//      line-height: 44px;
//      color: #fff;
//      font-size: 16px;
//      background: #ff001c;
//    }
//  }
//}
//
///*    下载APP页面*/
//.masks {
//  position: fixed;
//  left: 0;
//  top: 0;
//  width: 100%;
//  height: 100%;
//  background: rgba(0, 0, 0, 0.75);
//  z-index: 99;
//
//  .picture-box {
//    position: absolute;
//    left: 50%;
//    top: 50%;
//    transform: translate(-50%, -50%);
//
//    .picture {
//      display: block;
//      width: 280px;
//      // height: 360px;
//      background: rebeccapurple;
//      margin: 0 auto;
//    }
//
//    .btn {
//      width: 290px;
//      height: 44px;
//      border-radius: 22px;
//      background: #ed2e2e;
//      text-align: center;
//      line-height: 44px;
//      font-size: 16px;
//      color: #fff;
//      margin-top: 15px;
//    }
//  }
//
//  .sku {
//    width: 100%;
//    min-height: 500px;
//    border-radius: 8px 8px 0 0;
//    background: #fff;
//    position: absolute;
//    left: 0;
//    bottom: 0;
//    box-sizing: border-box;
//    padding: 14px;
//
//    .tp {
//      display: flex;
//      width: 100%;
//      position: relative;
//      margin-bottom: 20px;
//
//      .goods-cover {
//        width: 78px;
//        height: 78px;
//        border-radius: 4px;
//        background: #e0e0e0;
//      }
//
//      .goods-price {
//        width: 250px;
//        height: 78px;
//        display: flex;
//        // align-items: center;
//        flex-direction: column;
//        justify-content: space-around;
//        box-sizing: border-box;
//        padding-left: 12px;
//
//        .pris {
//          text-align: left;
//
//          .hjy {
//            color: #ed2e2e;
//            font-size: 13px;
//          }
//
//          .hjy-count {
//            color: #ed2e2e;
//            font-size: 18px;
//            font-weight: bold;
//            margin-right: 12px;
//          }
//
//          .spe-icon {
//            font-size: 14px;
//            color: #ed2e2e;
//            font-weight: bold;
//            margin-left: 4px;
//          }
//
//          .gwj {
//            color: #141f33;
//            font-size: 13px;
//          }
//        }
//
//        .guige {
//          text-align: left;
//          font-size: 13px;
//          color: #99a0ad;
//        }
//
//        .close {
//          width: 16px;
//          height: 16px;
//          /*background: url("../../assets/images/closesku.png") no-repeat;*/
//          background-size: 100% 100%;
//          position: absolute;
//          right: 4px;
//          top: 4px;
//        }
//      }
//    }
//
//    .out {
//      height: 200px;
//      overflow: hidden;
//      margin-bottom: 50px;
//
//      .inner {
//        height: 200px;
//        overflow: auto;
//      }
//    }
//
//    .status-box {
//      width: 100%;
//      margin-bottom: 8px;
//
//      .tits {
//        font-size: 16px;
//        color: #141f33;
//        margin-bottom: 12px;
//        text-align: left;
//      }
//
//      .status {
//        width: 100%;
//        overflow: hidden;
//
//        .box {
//          width: auto;
//          padding: 6px 20px;
//          background: #f0f2f5;
//          border-radius: 4px;
//          font-size: 14px;
//          color: #141414;
//          display: inline-block;
//          margin-right: 12px;
//          margin-bottom: 12px;
//          float: left;
//        }
//
//        .act {
//          background: #fae9e8;
//          color: #ed2e2e;
//        }
//      }
//    }
//  }
//
//  .num-box {
//    width: 100%;
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    font-size: 16px;
//    color: #141f33;
//    margin-bottom: 23px;
//
//    .handles {
//      .edge,
//      .add {
//        display: inline-block;
//        width: 28px;
//        height: 28px;
//        background: #f0f2f5;
//        border-radius: 4px;
//        color: #616b80;
//        font-size: 12px;
//        line-height: 28px;
//      }
//
//      .cou {
//        display: inline-block;
//        width: 45px;
//        height: 28px;
//        background: #f0f2f5;
//        border-radius: 4px;
//        color: #616b80;
//        font-size: 12px;
//        line-height: 28px;
//        margin: 0 4px;
//      }
//    }
//  }
//
//  .code-txt {
//    width: 100%;
//    text-align: center;
//    font-size: 12px;
//    color: #99a0ad;
//    margin-bottom: 4px;
//  }
//
//  .btn-box {
//    width: 100%;
//    height: 44px;
//    border-radius: 22px;
//    overflow: hidden;
//    display: flex;
//    justify-content: space-between;
//
//    .btn1 {
//      width: 50%;
//      height: 100%;
//      line-height: 44px;
//      background: #201e1d;
//      color: #fff;
//      font-size: 16px;
//    }
//
//    .btn2 {
//      background: #ff001c;
//    }
//
//    .btn3 {
//      width: 100%;
//      height: 100%;
//      line-height: 44px;
//      color: #fff;
//      font-size: 16px;
//      background: #ff001c;
//    }
//  }
//
//  /*    复制链接下载*/
//
//  .showCopyLinkContent {
//    position: absolute;
//    height: auto;
//    left: 50%;
//    margin-left: -130px;
//    top: 50%;
//    margin-top: -200px;
//
//    .showCopyLinkImg {
//      width: 260px;
//      height: 326px;
//    }
//
//    .showCopyLinkClose {
//      width: 31px;
//      height: 31px;
//      margin-top: 29px;
//    }
//  }
//}
//
//.ophead {
//  height: 40px;
//}
//
//.ophead.vs {
//  transition: height 0.6s ease-in-out;
//  height: 0;
//  display: none;
//}
//
//.wxStyle {
//  height: 100px;
//}
</style>
